import React from "react"
import useDarkMode from "use-dark-mode"

// Icons import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons"

const Toggle = () => {
  const darkMode = useDarkMode(false)

  return (
    <div>
      {darkMode.value ? (
        <FontAwesomeIcon
          icon={faMoon}
          onClick={darkMode.disable}
          className="nav__toggle"
        />
      ) : (
        <FontAwesomeIcon
          icon={faSun}
          onClick={darkMode.enable}
          className="nav__toggle"
        />
      )}
    </div>
  )
}

export default Toggle
