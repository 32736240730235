import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faGitlab,
  faLinkedin,
  faCodepen,
  faDribbble,
} from "@fortawesome/free-brands-svg-icons"

interface Props {
  author: string
}

const links = {
  linkedin: "https://www.linkedin.com/in/olivier-chemla-86a261106/",
  github: "https://github.com/olivech12",
  gitlab: "https://gitlab.com/OliveCh12",
  codepen: "https://codepen.io/SweetPerception",
  dribble: "https://dribbble.com/Junk"
}

const Footer = (props: Props) => {
  return (
    <footer>
      <div className="footer__content">
        <div className="footer__links">
          <a href={links.github} className="footer__link">
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href={links.gitlab} className="footer__link">
            <FontAwesomeIcon icon={faGitlab} />
          </a>
          <a href={links.codepen} className="footer__link">
            <FontAwesomeIcon icon={faCodepen} />
          </a>
          <a href={links.dribble} className="footer__link">
            <FontAwesomeIcon icon={faDribbble} />
          </a>
          <a href={links.linkedin} className="footer__link">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
        <p>
          Copyright © <strong>{new Date().getFullYear()}</strong>, Designed and
          Developed by <strong>{props.author}</strong>
        </p>
      </div>
    </footer>
  )
}

export default Footer
