/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// import Header from "./header"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"

// Import Fonts
import "typeface-barlow"

// Style SCSS
import "../styles/main.scss"

const Layout = ({ children, flexGrow = false, headerType = "standard" }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  return (
    <div className={flexGrow ? "flex--page-container" : ""}>
      <Header author={data.site.siteMetadata.author} type={headerType}></Header>
      <main>
        {children}
      </main>
      <Footer author={data.site.siteMetadata.author} />
    </div>
  )
}

export default Layout
