import React, { useState } from "react"
import { Link } from "gatsby"

// Website custom component
import Toggle from "./Toggle"

interface Props {
  author: string
  light: boolean
  type:
    | "light"
    | "standard"
    | "standard-no-borders"
    | "transparent--light"
    | "transparent--dark"
    | "accent"
}

const Header = (props: Props) => {
  const [darkMode, setDarkMode] = useState(false)
  return (
    <header className={`header--${props.type}`}>
      <nav>
        <Link to="/" className="nav__author">
          <span>{props.author.split(" ")[0]} </span>
          <strong>{props.author.split(" ")[1]}</strong>
          <span>.work</span>
        </Link>
        <div className="nav__links">
          <Link
            to="/"
            className={`nav__link`}
            activeClassName="nav__link--active">
            Accueil
          </Link>
          <Link
            to="/work"
            className={`nav__link`}
            activeClassName="nav__link--active"
            partiallyActive={true}>
            Projets
          </Link>
          {/* <Link
            to="/blog"
            className={`nav__link`}
            activeClassName="nav__link--active"
            partiallyActive={true}>
            Blog
          </Link> */}
          <Link
            to="/contact"
            className={`nav__link`}
            activeClassName="nav__link--active"
            partiallyActive={true}>
            Contact
          </Link>
          <Toggle darkMode={true} setDarkMode={setDarkMode} />
        </div>
      </nav>
    </header>
  )
}

export default Header
